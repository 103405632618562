import { Background, BackgroundType } from '@models/layeredBackground';

export function hasBackgroundLayer(backgroundLayers: Background[] | undefined): boolean {
    if (!backgroundLayers) {
        return false;
    }

    if (backgroundLayers.length === 0) {
        return false;
    }

    return backgroundLayers.some((background) => {
        if (!background.enabled) return false;

        if (background.type === BackgroundType.Image || background.type === BackgroundType.Video) {
            return !!background.config.mediaUrl;
        }

        if (background.type === BackgroundType.Color) {
            return !!background.config.color;
        }

        if (background.type === BackgroundType.Gradient) {
            return background.config.colors.some((gradient) => !!gradient.color);
        }
    });
}
